import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const FAQSection = (props) => {
  const { node } = props
  const { faq } = useStaticQuery(
    graphql`
      query faq {
        faq: allMarkdownRemark (filter: { frontmatter: { type: { eq: "faq" } } }) {
          edges {
            node {
              html
              frontmatter {
                title
                order
              }
            }
          }
        }
      }
    `
  )
  return (
    <section id={node.frontmatter.key} className="main">
      <header className="major">
        <h2>{ node.frontmatter.title }</h2>
        <div dangerouslySetInnerHTML={{ __html: node.html }} />
      </header>
      <ul className="features">
        {faq.edges
          .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
          .map(item =>
            <li>
              <h3>{ item.node.frontmatter.title }</h3>
              <div dangerouslySetInnerHTML={{ __html: item.node.html}} />
          </li>
        )}
      </ul>
    </section>
  )
}
export default FAQSection
