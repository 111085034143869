import React from 'react'

import '../../assets/scss/sponsors.scss';

const SponsorSection = (props) => {
  const { node } = props
  return (
    <section id={node.frontmatter.key} className="main special">
      <header className="major">
          <h2>{ node.frontmatter.title }</h2>
          <div className="sponContainer" dangerouslySetInnerHTML={{ __html: node.html }} />
        </header>
    </section>
  )
}
export default SponsorSection
